import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import SequentialEntrance from "vue-sequential-entrance";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "vue-sequential-entrance/vue-sequential-entrance.css";
import axios from "axios";
import VueAxios from "vue-axios";
import JsonExcel from "vue-json-excel";
import "./registerServiceWorker";
import VueQuillEditor from "vue-quill-editor";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
import VueApexCharts from "vue-apexcharts";
import KProgress from "k-progress";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueQuillEditor /* { default global options } */);
Vue.component("k-progress", KProgress);

window.OneSignal = window.OneSignal || [];
window.OneSignal.push(() => {
  window.OneSignal.init({
    appId: process.env.VUE_APP_ONESIGNAL,
    allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== "production",
  });
});

Vue.component("downloadExcel", JsonExcel);
Vue.use(VueAxios, axios);
Vue.use(Antd);
Vue.use(SequentialEntrance);
Vue.use(VueSweetalert2);

//and then use it in main.js
new Vue({
  router,
  store,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
